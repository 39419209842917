import React from 'react'

const About = () => (
    <section className="text-gray-400 max-w-3xl">
        <h2 className="mb-8 text-3xl text-blue-900">About Us</h2>
        <p className="mb-4 leading-relaxed">Hoopycake specializes in book design, web development
        and graphic design with a focus on one on one service,
        providing clients with a presence that is based on
        understanding their own unique identity.</p>
        
        <p className="mb-4 leading-relaxed">We have created web sites and print materials for a
        broad clientele, ranging from fine art galleries,
        public relations firms, fine furniture boutiques,
        non-profit organizations, fine artists and
        designers.</p>
        
        <p className="mb-4 leading-relaxed">All projects from small to large are approached
        with equal commitment and professionalism.</p>
    </section>
)

export default About