import React from 'react'
import { graphql, useStaticQuery, withPrefix } from 'gatsby'
import Img from 'gatsby-image'

const Books = () => {

    const { allFile: { nodes } } = useStaticQuery(graphql`
        query {
            allFile(
                filter: {
                    relativeDirectory: { eq: "books" }
                },
                sort: {
                    fields: base, order: ASC
                }
            ) {
                nodes {
                    base
                    childImageSharp {
                        fluid(
                            maxWidth: 389,
                            quality: 100
                        ) {
                            ...GatsbyImageSharpFluid_noBase64
                        }
                    }
                }
            }
        }    
    `)

    return (
        <section className="mt-16 pt-16 border-t-2 border-gray-200 text-gray-400 max-w-3xl">
            <h2 className="mb-8 text-2xl text-blue-900">Book Design</h2>
            <Img fluid={ nodes[0].childImageSharp.fluid }
                className="mb-2"
                style={{ maxWidth: '389px' }}
                alt="Ruven Afanador: SOMBRA book cover" />
            <p className="mb-4 leading-relaxed">
                <a className="text-gray-500 underline hover:text-blue-900"
                    href={ withPrefix('/download/CUL119.03_Sombra PR2_2.pdf') }
                    target="_BLANK">
                    Ruven Afanador: SOMBRA</a> (click for press release)<br />
                Merrell Publishers, 2004</p>
            
            <p className="mb-4 leading-relaxed">Photographs by Ruven Afanador<br />
                Foreword by Jean-Paul Gaultier<br />
                Hardback, ISBN 1-85894-249-7<br />
                192pages 36.8 x 28.9cm, 120 Quadtone illustrations</p>
            
            <p className="mb-4 leading-relaxed">"Beautifully designed and lavishly produced"<br />
                - Merrell Publishing Catalog, Spring 2004</p>
            
            <p className="mb-12 leading-relaxed">International Photography Awards,<br />
                Fine Art Photography Book Category, 2nd place</p>
            
            <Img fluid={ nodes[1].childImageSharp.fluid }
                className="mb-2"
                style={{ maxWidth: '389px' }}
                alt="Hatje Cantz: Louise Lawler and Others book cover" />
            <p className="mb-4 leading-relaxed">Louise Lawler and Others<br />
                Hatje Cantz, 2004</p>
            
            <p className="mb-4 leading-relaxed">Contributions by:<br />
                Jack Bankowsky, Isabelle Graw, Birgit Pelzer,<br />
                Philipp Kaiser, George Baker and Andrea Fraser</p>
            
            <p className="mb-4 leading-relaxed">Published on the occasion of the exhibition<br />
                "Louise Lawler and Others"<br />
                at the Museum für Gegenwartskunst, Basel,<br />
                15 May ­ 29 August 2004</p>
        </section>
    )
}

export default Books