import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'

import { FluidObject } from 'gatsby-image'
import Lightbox from './lightbox'
import work from '../data/work.json'

type ImageObject = {
    base: string,
    childImageSharp: {
        fluid: FluidObject & {
            presentationWidth: number,
            presentationHeight: number,
        }
    }
}

const Work = () => {

    const [lightboxOpen, setLightboxOpen] = React.useState(false)
    const [windowSize, setWindowSize] = React.useState([0, 0])
    const [currentIndex, setCurrentIndex] = React.useState(0)
    const [showMore, setShowMore] = React.useState(false)

    React.useEffect(() => {
        document.body.style.overflow = (lightboxOpen) ? 'hidden' : 'unset'
    }, [lightboxOpen])

    React.useEffect(() => {
        const handleResize = () => {
            setWindowSize([window.innerWidth, window.innerHeight])            
        }

        window.addEventListener('resize', handleResize, false)

        return () => {
            window.removeEventListener('resize', handleResize, false)
        }
    }, [])

    const onClick = (event: React.MouseEvent, index: number) => {
        event.preventDefault()
        setCurrentIndex(index)
        setWindowSize([window.innerWidth, window.innerHeight]) 
        setLightboxOpen(true)
    }

    const onShowMore = (event: React.MouseEvent) => {
        setShowMore(true)
    }
    
    const onClose = (event?: React.MouseEvent) => {
        setLightboxOpen(false)
    }
    
    const onPrev = (event?: React.MouseEvent) => {
        const newIndex = (currentIndex > 0) ?
            currentIndex - 1 :
            nodes.length - 1
        setCurrentIndex(newIndex)
    }
    
    const onNext = (event?: React.MouseEvent) => {
        const newIndex = (currentIndex < nodes.length - 1) ?
            currentIndex + 1 :
            0
        setCurrentIndex(newIndex)
    }

    const { allFile: { nodes } }: { allFile: { nodes: ImageObject[] } } = useStaticQuery(graphql`
        query {
            allFile(
                filter: {
                    relativeDirectory: { eq: "work" }
                },
                sort: {
                    fields: base, order: ASC
                }
            ) {
                nodes {
                    base
                    childImageSharp {
                        fluid(
                            maxWidth: 1200,
                            quality: 100
                        ) {
                            ...GatsbyImageSharpFluid_noBase64
                            presentationWidth
                            presentationHeight
                        }
                    }
                }
            }
        }    
    `)

    const showLightbox = () => {

        // get width and height
        const boundaries = [windowSize[0] - 40, windowSize[1] - 40]
        const presentationSize = [
            nodes[currentIndex].childImageSharp.fluid.presentationWidth,
            nodes[currentIndex].childImageSharp.fluid.presentationHeight,
            nodes[currentIndex].childImageSharp.fluid.aspectRatio
        ]

        let style = {
            width:  presentationSize[0] + 'px',
            height: presentationSize[1] + 'px',
        }
        if (presentationSize[0] > boundaries[0] || presentationSize[1] > boundaries[1]) {
            style = (presentationSize[2] > boundaries[0] / boundaries[1]) ? {
                width: boundaries[0] + 'px',
                height: boundaries[0] / presentationSize[2] + 'px',
            } : {
                width: boundaries[1] * presentationSize[2] + 'px',
                height: boundaries[1] + 'px',
            }
        }

        return lightboxOpen &&
            <Lightbox onClose={ onClose } onPrev={ onPrev } onNext={ onNext }>
                <figure style={ style }>
                    <Img fluid={ nodes[currentIndex].childImageSharp.fluid }
                        alt={ work[currentIndex].title } />
                </figure>
            </Lightbox>
    }

    return (
        <section className="mt-16 pt-16 border-t-2 border-gray-200 max-w-3xl">
            <h2 className="mb-8 text-2xl text-blue-900">Recent Work</h2>
            <div className="flex flex-wrap items-start lg:-mx-4">
            {
                nodes.map((node, index) => 
                    (index < 6 || showMore) && (
                        <div className="block w-full lg:w-1/2 lg:px-4 mb-8"
                            key={ index }>
                            <figure>
                                <button className="w-full mb-4 relative group"
                                    onClick={ (event) => onClick(event, index) }>
                                    <Img className="border rounded-md"
                                        fluid={ node.childImageSharp.fluid }
                                        alt={ 'Screeshot of' + work[index].title } />
                                    <div className="opacity-0 absolute inset-0 bg-black rounded-md
                                        transition-opacity duration-500 ease-in-out group-hover:opacity-50"
                                        role="presentation"></div>
                                    <div className="opacity-0 absolute top-1/2 -mt-6 w-full
                                        transition-opacity duration-500 ease-in-out group-hover:opacity-100">
                                        <p className="mx-auto py-1 px-4 inline-block text-sm
                                            border border-gray-300 rounded-md text-white"
                                            style={{ borderWidth: '3px' }}>View</p>
                                    </div>
                                </button>
                                <figcaption className="text-gray-500">
                                    <span>
                                        { work[index].title }
                                    </span>
                                    <a className="block mt-2 text-sm underline hover:text-blue-900"
                                        href={ work[index].url }
                                        target="_BLANK">
                                        { work[index].url }
                                    </a>
                                </figcaption>
                            </figure>
                            
                        </div>
                ))
            }
            </div>
            { !showMore &&
                <button className="py-2 px-6 border border-gray-200 rounded-md text-gray-500
                    hover:border-blue-900 hover:text-blue-800"
                    style={{ borderWidth: '3px' }}
                    onClick={ onShowMore }>More</button>
            }
            { showLightbox() }
        </section>
    )
}

export default Work