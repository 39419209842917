import React from 'react'

import Seo from 'components/seo'
import About from 'components/about'
import Work from 'components/work'
import Books from 'components/books'
import Logos from 'components/logos'
import Contact from 'components/contact'

const Home = () => (
    <>
        <Seo title="Hoopycake" />
        <About />
        <Work />
        <Books />
        <Logos />
        <Contact />
    </>
)

export default Home