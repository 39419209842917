import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import { FluidObject } from 'gatsby-image'

import logo from '../data/logo.json'

type ImageObject = {
    base: string,
    childImageSharp: {
        fluid: FluidObject & {
            presentationWidth: number,
            presentationHeight: number,
        }
    }
}

const Logos = () => {

    const { allFile: { nodes } }: { allFile: { nodes: ImageObject[] } } = useStaticQuery(graphql`
        query {
            allFile(
                filter: {
                    relativeDirectory: { eq: "logo" }
                },
                sort: {
                    fields: base, order: ASC
                }
            ) {
                nodes {
                    base
                    childImageSharp {
                        fluid(
                            maxWidth: 504,
                            quality: 100
                        ) {
                            ...GatsbyImageSharpFluid_noBase64
                        }
                    }
                }
            }
        }    
    `)

    return (
        <section className="mt-16 pt-16 border-t-2 border-gray-200 max-w-3xl">
            <h2 className="mb-8 text-2xl text-blue-900">Logos</h2>
            
            <div className="flex flex-wrap items-start lg:-mx-4">
            {
                nodes.map((node, index) => (
                    <div className="block w-full lg:w-1/2 lg:px-4 mb-8"
                        key={ index }>
                        <figure className="w-full mb-4">
                            <Img className="border rounded-md"
                                fluid={ node.childImageSharp.fluid }
                                alt={ 'Logo of' + logo[index].title } />
                            <figcaption className="mt-4 text-gray-500">
                                <span>
                                    { logo[index].title }
                                </span>
                                <span className="block mt-2 text-sm">
                                    { logo[index].subtitle }
                                </span>
                            </figcaption>
                        </figure>
                        
                    </div>
                ))
            }
            </div>
        </section>
    )
}

export default Logos