import React from 'react'

type LightboxProps = {
    onClose: (event?: React.MouseEvent) => void,
    onPrev: (event?: React.MouseEvent) => void,
    onNext: (event?: React.MouseEvent) => void,
}
const Lightbox: React.FC<LightboxProps> = ({ onClose, onPrev, onNext, children }) => {

    const escRef = React.useRef<HTMLButtonElement>(null)
    const nextRef = React.useRef<HTMLButtonElement>(null)
    const prevRef = React.useRef<HTMLButtonElement>(null)

    React.useEffect(() => {

        escRef.current?.focus()

        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                onClose()
            }
            if (event.key === 'Tab') {
                if (event.shiftKey) {
                    if (document.activeElement === escRef.current) {
                        nextRef.current?.focus()
                        event.preventDefault()
                    }
                }
                else {
                    if (document.activeElement === nextRef.current) {
                        escRef.current?.focus()
                        event.preventDefault()
                    }
                }
            }
        }

        document.addEventListener('keydown', handleKeyDown, false)

        return () => {
            document.removeEventListener('keydown', handleKeyDown, false)
        }
    }, [])

    return (
        <>
            <div className="fixed inset-0 bg-gray-800 opacity-75 z-10"
                role="presentation">
            </div>
            <div className="fixed inset-0 z-10"
                role="dialog" aria-label="Image enlargement">
                <div className="fixed inset-1 md:inset-3 lg:inset-5 flex items-center justify-center"
                    aria-modal="true"
                    role="document">
                    { children }
                    <button className="absolute top-0 right-0 text-white"
                        onClick={ onClose }
                        ref={ escRef }>
                        <svg className="fill-current h-6 w-6"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24" fill="none" stroke="currentColor">
                            <defs>
                                <filter id="shadow">
                                    <feDropShadow dx="0.2" dy="0.4" stdDeviation="0.2"/>
                                </filter>
                            </defs>
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12"
                                filter="url(#shadow)" />
                            <title>Close lightbox</title>
                        </svg>
                    </button>                    
                    <button className="absolute top-1/2 left-0 text-white"
                        onClick={ onPrev }
                        ref={ prevRef }>
                        <svg className="h-6 w-6"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <title>Previous</title>
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7"
                                filter="url(#shadow)" />
                        </svg>
                    </button>
                    <button className="absolute top-1/2 right-0 text-white"
                        onClick={ onNext }
                        ref={ nextRef }>
                        <svg className="h-6 w-6"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <title>Next</title>
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7"
                                filter="url(#shadow)" />
                        </svg>
                    </button>
                </div>
            </div>
        </>
    )
}

export default Lightbox