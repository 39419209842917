import React from 'react'

import Mailto from './mailto'

const Contact = () => (
    <section className="mt-16 pt-16 border-t-2 border-gray-200 text-gray-400 max-w-3xl">
        <h2 className="mb-8 text-2xl text-blue-900">Contact</h2>
        <p className="mb-4 leading-relaxed"><strong>Tel</strong>: (718) 274-1775</p>
        <p className="mb-4 leading-relaxed"><strong>Fax</strong>: (718) 274-1841</p>
        <p className="mb-4 leading-relaxed"><strong>E-mail</strong>: <Mailto
                className="text-gray-500 underline hover:text-blue-900"
                href="info@hoopycake.com"
                type="mailto" /></p>

        <p className="mb-4 leading-relaxed">
            Hoopycake is committed to making this website available to 
            as many people as possible and is engaged in continued efforts 
            to ensure that this website is accessible to all persons who 
            wish to use it, including those with special needs - visual, 
            hearing, cognitive and motor impairments. Our efforts in that 
            regard are ongoing. If you come across a page or feature you 
            find inaccessible or difficult to use, please send your 
            feedback to: <Mailto
                className="text-gray-500 underline hover:text-blue-900"
                href="info@hoopycake.com"
                type="mailto" />.</p>
    </section>
)

export default Contact